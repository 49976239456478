export function cacheUpdateMatchState(payload) {
  return { type: `CACHE__UPDATE_MATCH_STATE`, payload };
}

export default function cacheReducer(cache, action) {
  return { ...cache, matchState: matchStateReducer(cache.matchState, action) };
}

function matchStateReducer(state, action) {
  if (!action?.type) return state;

  switch (action.type) {
    case `___RESET___`:
    case `SELECT_MATCH`:
      return {};
    case `CACHE__UPDATE_MATCH_STATE`:
      return action.payload;
    case `UPDATE_EVENT`:
    case `DELETE_EVENT`:
      return findMatchStateBefore(state, eventKey(action.payload));
    default:
      return state;
  }
}

export function eventKey(event) {
  return `${event?.uuid}|${event?.time}`;
}

export function findMatchStateBefore(state, eventKey) {
  return findMatchStateGeneral(
    state,
    (s) => s?.eventKey === eventKey,
    state,
    (matched) => (matched?.prev ? matched.prev : {})
  );
}

export function findMatchState(state, eventsKeys) {
  return findMatchStateGeneral(
    state,
    stateFoundMeta(eventsKeys),
    {},
    (matched) => matched
  );
}

function stateFoundMeta(eventsKeys) {
  return function stateFoundPredicate(state) {
    const index = eventsKeys.indexOf(state?.eventKey);
    if (index === -1) return false;
    const keysToTest = eventsKeys.slice(0, index + 1).reverse();
    let i = 0;
    let s = state;
    while (i < keysToTest.length && keysToTest[i] === s?.eventKey) {
      i++;
      s = s.prev;
      if (i === keysToTest.length && !s?.eventKey) return true;
    }
    return false;
  };
}

function findMatchStateGeneral(state, predicate, notFound, found, depth = 0) {
  if (!state?.eventKey) return {};
  if (predicate(state)) return found(state);
  if (!state?.prev) return null;

  const fromPrev = findMatchStateGeneral(
    state.prev,
    predicate,
    notFound,
    found,
    depth + 1
  );
  if (depth === 0 && fromPrev === null) return notFound;
  return fromPrev;
}

export function findMatchStateForEvent(state, event) {
  const eventK = eventKey(event);
  return findMatchStateGeneral(
    state,
    (s) => s?.eventKey === eventK,
    state,
    (matched) => matched
  );
}
