import React, { Suspense, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";

import Loading from "./components/loading";
import "./css/style.css";
import "./utils/icons";
import { AppProvider } from "./hooks/use-app-context";
import ErrorCatcher from "./components/error-fallback";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

export default function App({ children }) {
  const [show, setShow] = useState(false);
  useEffect(() => setShow(true), []);
  return (
    <ErrorCatcher>
      <Suspense fallback={<Loading />}>
        {show && (
          <QueryClientProvider client={queryClient}>
            <AppProvider>
              <Toaster />
              {children}
            </AppProvider>
          </QueryClientProvider>
        )}
      </Suspense>
    </ErrorCatcher>
  );
}
