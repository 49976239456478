import React, { createContext, useContext, useRef, useCallback } from "react";

import useLocallyPersistedReducer from "./use-locally-persisted-reducer";
import globalReducer, { init } from "../reducers";
import cacheReducer from "../reducers/cache";

export const AppContext = createContext();

export function AppProvider({ children }) {
  const [state, stateDispatch] = useLocallyPersistedReducer(
    globalReducer,
    null,
    `app`,
    init
  );

  const cacheRef = useRef({});
  const getCache = useCallback(() => cacheRef.current, [cacheRef]);

  const cacheDispatch = useCallback(
    (action) => {
      cacheRef.current = cacheReducer(cacheRef.current, action);
    },
    [cacheRef]
  );

  const dispatch = useCallback(
    (action) => {
      cacheDispatch(action);
      stateDispatch(action);
    },
    [stateDispatch, cacheDispatch]
  );
  return (
    <AppContext.Provider
      value={{
        state,
        stateDispatch,
        getCache,
        cacheDispatch,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context)
    return {
      auth: {},
      state: {
        currentMatchId: `ssr`,
        matches: { ssr: { id: `ssr`, events: [] } },
      },
      stateDispatch: (...args) => {},
      getCache: () => {},
      cacheDispatch: (...args) => {},
      dispatch: (...args) => {},
    };
  return context;
}
