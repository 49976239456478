import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

import { Button } from "../components/form";

export default function ErrorCatcher({ children }) {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <ErrorFallback resetErrorBoundary={resetErrorBoundary} />
      )}
      onReset={reset}
    >
      {children}
    </ErrorBoundary>
  );
}

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-red-500">
      <div>
        <FA fixedWidth={true} icon="fa-circle-exclamation" size="2xl" />
        <span className="ml-4 font-bold text-xl">Unexpected error ...</span>
      </div>
      <div>
        <Button onClick={() => resetErrorBoundary()}>Try again</Button>
      </div>
    </div>
  );
}
