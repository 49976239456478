import React from "react";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

export default function Loading({
  icon = `fa-arrows-spin`,
  message = `Čekám na data ...`,
}) {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div>
        {icon && <FA fixedWidth={true} icon="fa-arrows-spin" size="2xl" spin />}
        <span className="ml-4 font-bold text-xl">{message}</span>
      </div>
    </div>
  );
}
