import { v4 as uuidv4 } from "uuid";

export { default as eventsDef } from "./events-def";

export function actionAddEvent(payload) {
  return { type: `ADD_EVENT`, payload: { uuid: uuidv4(), ...payload } };
}

export function actionUpdateEvent(payload) {
  return { type: `UPDATE_EVENT`, payload: fixEventAfterEdit(payload) };
}

export function actionCloseEvent() {
  return { type: `CLOSE_EVENT`, payload: null };
}

export function actionDeleteEvent(uuid) {
  return { type: `DELETE_EVENT`, payload: { uuid } };
}

export function fixEventAfterEdit(event) {
  if (!event?.localTime) {
    event.time = undefined;
  } else {
    const localTimeParsed = event.localTime.match(
      /([\d]{2}):([0-5][\d])(\.([\d]{1,3}))?/
    );
    event.time =
      parseInt(localTimeParsed[1] ?? 0, 10) * 60000 +
      parseInt(localTimeParsed[2] ?? 0, 10) * 1000 +
      parseInt((localTimeParsed[4] ?? `0`).padEnd(3, `0`), 10);
  }
  return event;
}

export function fixEvent(event) {
  if (!event.time && event.time !== 0) event.localTime = ``;
  else {
    const minutes = ~~(event.time / 60000);
    const seconds = ~~((event.time % 60000) / 1000);
    const millis = event.time % 1000;
    event.localTime = `${minutes.toString().padStart(2, `0`)}:${seconds
      .toString()
      .padStart(2, `0`)}${millis !== 0 ? `.` + millis : ``}`;
  }
  return event;
}

export default function reducer(events = [], action) {
  switch (action?.type) {
    case `ADD_EVENT`:
      return [...events, action.payload].sort(compareEventsByTime);
    case `UPDATE_EVENT`:
      return events
        .map((event) =>
          sameEvent(event, action.payload)
            ? { ...event, ...action.payload }
            : event
        )
        .sort(compareEventsByTime);
    case `DELETE_EVENT`:
      return events.filter((event) => !sameEvent(event, action.payload));
    case `UPDATE_MATCH_START`:
      const home = getRosterExtraStarting(action.payload?.homeRosterExtra);
      const guest = getRosterExtraStarting(action.payload?.guestRosterExtra);
      const starting = events.find((e) => e?.type === `starting_players`) ?? {};
      const updatedStarting = {
        ...starting,
        uuid: starting.uuid ?? uuidv4(),
        time: starting.time ?? 0,
        type: starting.type ?? `starting_players`,
        home: {
          attack: [],
          defense: [],
          reserves: [],
          captain: undefined,
          coaches: [],
          ...home,
        },
        guest: {
          attack: [],
          defense: [],
          reserves: [],
          captain: undefined,
          coaches: [],
          ...guest,
        },
      };
      return [
        updatedStarting,
        ...events.filter((e) => e?.type !== `starting_players`),
      ];
    default:
      return events;
  }
}

function getRosterExtraStarting(rosterExtra) {
  const result = { attack: [], defense: [], reserves: [] };
  (rosterExtra ?? []).forEach((p) => {
    result[p.role].push({ id: p.uuid, shirt: p.shirt });
  });
  return result;
}

function compareEventsByTime(a, b) {
  if (a?.time === b?.time) return 0;
  if (!a?.time && a?.time !== 0) return 1;
  if (!b?.time && b?.time !== 0) return -1;
  return a.time < b.time ? -1 : 1;
}

export function sameEvent(a, b) {
  const u1 = typeof a === `object` && a ? a.uuid : a;
  const u2 = typeof b === `object` && b ? b.uuid : b;
  return u1 && u2 && u1 === u2;
}
