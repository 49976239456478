export function findColor(def) {
  switch (def) {
    case `black`:
      return `bg-gray-300`;
    case `white`:
      return `bg-white`;
    case `gray`:
      return `bg-gray-200`;
    case `red`:
      return `bg-red-100`;
    case `orange`:
      return `bg-orange-100`;
    case `yellow`:
      return `bg-yellow-100`;
    case `green`:
      return `bg-green-100`;
    case `blue`:
      return `bg-blue-100`;
    case `purple`:
      return `bg-purple-200`;
    case `pink`:
      return `bg-pink-200`;
    default:
      return `bg-white`;
  }
}
