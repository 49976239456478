import matchReducer from "./match";

export function actionSelectMatch(selected, serverMatch) {
  return { type: `SELECT_MATCH`, payload: { selected, serverMatch } };
}

export function getCurrentMatch(state) {
  if (!state?.currentMatchId || !state?.matches) return null;

  return state.matches[state.currentMatchId];
}

export default function globalReducer(state, action) {
  if (action?.type === `___RESET___`) return init(null);
  return {
    ...state,
    auth: authReducer(state?.auth, action),
    currentMatchId: currentMatchReducer(state.currentMatchId, action),
    matches: {
      ...state.matches,
      [state.currentMatchId]: matchReducer(
        state.matches[state.currentMatchId],
        action
      ),
      ...(action.type === `SELECT_MATCH` &&
      state.currentMatchId !== action.payload.selected
        ? {
            [action.payload.selected]: matchReducer(
              state.matches[action.payload.selected],
              action
            ),
          }
        : {}),
    },
  };
}

function currentMatchReducer(currentMatchId, action) {
  if (action.type === `SELECT_MATCH`) return action.payload.selected;
  return currentMatchId;
}

export function actionSetAuth(auth) {
  return { type: `SET_AUTH`, payload: { auth } };
}

function authReducer(auth, action) {
  if (action.type === `SET_AUTH`) return action.payload.auth;
  return auth;
}

export function init(param) {
  return { currentMatchId: null, matches: {} };
}
