export function actionSelectEvent(selected) {
  return { type: `SELECT_EVENT`, payload: { selected } };
}

export function actionCloseEvent() {
  return { type: `CLOSE_EVENT`, payload: null };
}

export default function reducer(eventDetail = {}, action) {
  switch (action?.type) {
    case `SELECT_EVENT`:
      return { ...eventDetail, ...action.payload };
    case `CLOSE_EVENT`:
    case `DELETE_EVENT`:
    case `UPDATE_EVENT`:
      return {};
    case `ADD_EVENT`:
      return !eventDetail?.selected
        ? { ...eventDetail, selected: action.payload?.uuid }
        : eventDetail;
    default:
      return eventDetail;
  }
}
