import { useReducer, useCallback } from "react";
import { dequal } from "dequal";

export default function useLocallyPersistedReducer(
  reducer,
  defaultState,
  storageKey,
  init = null
) {
  const sync = useCallback(
    (value) => {
      if (value === null) {
        localStorage.removeItem(storageKey);
      } else {
        localStorage.setItem(storageKey, JSON.stringify(value));
      }
    },
    [storageKey]
  );

  const reducerWrapper = useCallback(
    (state, action) => {
      const retval = reducer(state, action);
      if (!dequal(state, retval)) sync(retval);
      return retval;
    },
    [reducer, sync]
  );

  const hookVars = useReducer(reducerWrapper, defaultState, (defaultState) => {
    const persisted =
      typeof window !== `undefined`
        ? JSON.parse(localStorage.getItem(storageKey))
        : null;
    return persisted !== null
      ? persisted
      : init !== null
      ? init(defaultState)
      : defaultState;
  });

  return hookVars;
}
